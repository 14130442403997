
h2 {
	font-size: 15px;
	display: inline;
}

h3 {
	font-size: 15px;
	display: inline;
}

* {	margin:0; padding:0; list-style:none; font-size:11px; font-family:tahoma; list-style:none; color:#a9a9a9; line-height:16px; border:0}

body{background:url(../../assets/images/top_rep.jpg) repeat-x #222222; }
.clear{font-size:0; line-height:0; clear:both; height:0px}
.header{height:222px}
.header, .content_middle_in, .content, .footer_in{ width:782px; margin-left:auto; margin-right:auto}
.menu_header{width:184px; margin-top:23px; float:left}
.menu_header a, .menu_header a img{display:block; }
.header img{display:block}
.pik_header{float:right; margin-top:3px}

.content_middle{ width:100%; background:url(../../assets/images/mid_rep.jpg) repeat-x; clear:both}
.content_middle_inner{ width:100%; background:url(../../assets/images/inner_rep.gif) repeat-x; clear:both}
.content_middle_inner p{ margin-bottom:10px}
.content_middle_in{padding-bottom:15px}
.pik_man{float:left; margin-top:56px; }
.welcome{width:440px; float:left; margin-top:11px; margin-left:29px}
.request{margin-left:41px; margin-top:30px; }

.home_box_top{width:225px; background:url(../../assets/images/line_top.jpg) no-repeat top right; padding-right:20px; text-align:justify}
.home_box_top img{vertical-align:top}
.home_box_top img.icon{margin-right:5px}
.home_box_top p{padding-bottom:10px; padding-top:5px}
.home_box_bot{width:245px; background:url(../../assets/images/line_bot.jpg) no-repeat bottom right}
.home_box_rep{width:245px; background:url(../../assets/images/line_rep.jpg) repeat-y top right; margin-top:10px; float:left; margin-right:20px}
.backgrond_n{background:none; margin-right:0}


.footer{background: url(../../assets/images/footer_rep.jpg) repeat-x 0 15px; width:%100; height:42px; clear:both; padding-top:15px; padding-bottom:15px}
.footer_in{padding-top:5px}
.footer_in p{text-align:center; font-size:10px; color:#6d6d6d}
.footer_in p a{color:#6d6d6d; text-decoration:none; font-size:10px}
.footer_in p a:hover{color:#a9a9a9}

.title{background: url(../../assets/images/px_rep.gif) repeat-x left bottom; padding:11px 0; margin:0 0 15px 0}
.titles, h1{background: url(../../assets/images/px_rep.gif) repeat-x left bottom; color:#c14c9d; font-weight:bold;padding:11px 0; margin:0 0 15px 0}
p.prodTitle{background: url(../../assets/images/px_rep.gif) repeat-x left bottom; color:#c14c9d; font-weight:bold;padding:11px 0; margin:0 0 15px 0; clear:both}

.contacts{float:left; width:300px; margin-bottom:20px; margin-right:30px}
.b{font-weight:bold}
.contacts .titles{ margin-right:50px;background: url(../../assets/images/px_rep.gif) repeat-x left bottom; color:#c14c9d; font-weight:bold;padding:11px 0; margin:0 0 15px 0}	
.products .titles{ margin-right:50px;background: url(../../assets/images/px_rep.gif) repeat-x left bottom; color:#c14c9d; font-weight:bold;padding:11px 0; margin:0 0 15px 0}	

.box_product{width:700px}
ul {width:300px;margin-bottom:20px; float:left}
ul li{background:url(../../assets/images/ar_white.gif) no-repeat 0 6px; padding-left:15px; width:300px; }
.picture{float:right; padding:0 0 10px 0; margin:0; background:none;  width:300px }
.picture img{border:1px solid #666666; padding:3px}	

.contact_div ul{margin:0 20px 0 0; padding:0}
.contact_div ul li{width:300px; background:none;margin:0; padding:0}
.contact_div ul li h2{background: url(../../assets/images/px_rep.gif) repeat-x left bottom; color:#c14c9d; font-weight:bold;padding:11px 0; margin:0 0 15px 0; display:block; font-size:11px}
.contact_div p{ clear:both; padding-top:10px}
.error, .mes_ok{color:#FF0000; margin-bottom:10px}

			
/* --- Contact Form --- */
#contactForm{width:375px; background:url(../../assets/images/px_vertical.gif) right repeat-y; overflow:hidden; float:left; }
#contactForm * { font-size:1em; color:#5b5b5b; color:#a9a9a9 }
#contactForm label, #contactForm input { display: -moz-inline-box; display: inline-block; vertical-align:top;}
#contactForm fieldset { padding:2px 0; border:0}
#contactForm label { width:121px; height:25px; line-height:25px; font-weight:bold; color:#be4f9e; float:left}
#contactForm input { width:220px; height:12px; line-height:13px; padding:2px; background:#2d2d2d;border:1px solid #7f9db9 }
#contactForm input.check{width:12px; margin-top:2px; margin-right:10px}
#contactForm .area{width:200px; float:left}
#contactForm .area p{clear:both}
 
#contactForm select { width:226px; height:12px;height:18px; padding:2px; background:#2d2d2d;border:1px solid #7f9db9 }
#contactForm textarea { width:220px; height:55px; line-height:15px; padding:5px 2px 10px 2px;font-family:"trebuchet MS"; overflow:auto;background:#2d2d2d; border:1px solid #7f9db9}

#contactForm #contactsend { width:57px; height:26px; background: url(../../assets/images/btn_submit.gif) no-repeat; padding:0; cursor:pointer; border:0; margin-left:121px; color:#FFFFFF; margin-top:3px}
.contact{width:377px; float:left; margin-left:25px}