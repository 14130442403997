/* These are standard sIFR styles... do not modify */

.sIFR-flash {
	visibility: visible !important;
	margin: 0;
}

.sIFR-replaced {
	visibility: visible !important;
}

span.sIFR-alternate {
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	height: 0;
	display: block;
	overflow: hidden;
}

/* Hide Adblock Object tab: the text should show up just fine, not poorly with a tab laid over it. */
.sIFR-flash + div[adblocktab=true] {
  display: none !important;
}



	
.sIFR-hasFlash h3 {
	visibility: hidden;
	font-size: 16px;}
	
